@import './colors.scss';
@import './landing.scss';

html,
body {
  overflow-x: hidden;
  height: auto;
  // height: 100vh;
}

#root {
  height: 100%;
}

main {
  height: 100vh;
  width: 100vw;
}

pre {
  white-space: pre-wrap !important; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap !important; /* Opera 4-6 */
  white-space: -o-pre-wrap !important; /* Opera 7 */
  word-wrap: break-word !important; /* Internet Explorer 5.5+ */
}

.resources {
  p {
    max-width: 60rem;
    padding: 1.5rem;
    padding-top: 0;
    margin: auto;

    p {
      margin-bottom: 0 !important;
    }
  }
}

.resources {
  .section {
    max-width: 60rem;
    padding: 1.5rem;
    padding-top: 0;
    margin: auto;

    .section {
      margin-bottom: 0 !important;
    }
  }
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.5rem;
  max-width: 60rem;
  margin: auto;
  position: relative;

  div {
    position: relative;
    width: auto;
    padding: 0;
    margin: 0;
    margin-bottom: 3rem;

    img {
      height: 15rem;
      width: auto;

      position: relative;
      z-index: 1;
      padding: 0;
      margin: 0;
    }
  }

  div:hover::before {
    content: 'Download';
    display: flex;
    border: 3px solid var(--primary-color);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #00000040;
    color: white;
    font-weight: 900;
    z-index: 100000;
    position: absolute;
    text-shadow: 4px 4px 4px black;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
}

.survey-card-button-box {
  width: 200px;
  .button {
    width: 10rem;
    margin-bottom: 1rem;
  }
}

.main-container {
  padding: 6rem 2rem;
  display: flex;
  height: 100%;
  width: 100%;
}

.survey-container-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

@media only screen and (max-width: 1224px) and (min-width: 769px) {
  .main-container {
    .card-content {
      flex-direction: column !important;
      width: 100% !important;
    }

    .content:not(:last-child) {
      margin-bottom: 1rem;
    }

    .survey-card-img-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.8rem;
    }

    .survey-card-button-box {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .survey-card-single-button {
        width: 7rem;
      }

      .survey-card-multiple-buttons {
        width: 7rem;
      }
    }

    .survey-progress-box {
      p {
        margin-bottom: 0.3rem;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .main-container {
    // overflow-y: auto;
    padding: 0.5rem;
    display: flex;
    flex-direction: column-reverse;
    padding-top: 5rem;
    padding-bottom: 1rem;

    .main-container-left {
      padding-right: 0 !important;
      margin-right: 0 !important;
    }
    .main-container-right {
      width: 100%;
      flex: 1;
      margin-bottom: 1rem;

      .card {
        width: 100% !important;
      }
    }

    .card-content {
      flex-direction: column !important;
      width: 100% !important;
    }

    .content:not(:last-child) {
      margin-bottom: 1rem;
    }

    .survey-card-img-box {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 0.8rem;
    }

    .survey-card-button-box {
      width: 100%;
      display: flex;
      justify-content: center;

      .survey-card-single-button {
        width: 100%;
      }

      .survey-card-multiple-buttons {
        width: 8rem;
      }
    }

    .survey-progress-box {
      p {
        margin-bottom: 0.3rem;
      }
    }
  }
}
