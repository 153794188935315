.sidebar-toggle-button {
  position: relative;
  z-index: 1500;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 1224px) {
  .sidebar-toggle-button {
    width: 62px;
    height: 62px;
  }
}

.pulse-sidebar {
  position: absolute;
}

.pulse-sidebar-sticky {
  position: fixed;
}

.pulse-sidebar,
.pulse-sidebar-sticky {
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 100%;
  align-items: center;
  z-index: 500;
}

.sidebar-closed {
  pointer-events: none;
}

.sidebar-open {
  pointer-events: auto;
}

.sidebar-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  background: rgb(225, 225, 225);
}

.sidebar-ul {
  padding: 25px;
  position: relative;
  top: 40px;
  width: auto;
  margin: auto;
  color: black;
}

.sidebar-ul > *:last-child {
  border-bottom: none;
}

.sidebar-li {
  list-style: none;
  display: flex;
  margin: auto;
  cursor: pointer;
  width: auto;
  border-bottom: 2px solid black;
}

.text-placeholder {
  text-align: center;
  flex: 1;
  margin: 0 auto;
}

.sidebar-bottom-button {
  bottom: 1rem;
  padding: 1rem;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .sidebar-bottom-button {
    bottom: 3rem;
  }
}
