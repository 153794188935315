@import 'bulma/sass/utilities/_all';

$secondary: rgb(56, 73, 92);
$secondary-invert: findColorInvert($secondary);

$addColors: (
  'secondary': (
    $secondary,
    $secondary-invert,
  ),
);

$colors: map-merge($colors, $addColors);

@import 'bulma/sass/base/_all';
@import 'bulma/sass/form/_all';
@import 'bulma/sass/components/_all';
@import 'bulma/sass/grid/_all';
@import 'bulma/sass/helpers/_all';
@import 'bulma/sass/layout/_all';

// @import "bulma/sass/elements/_all";
// NOTE: This component messes up the documentation layout. So we need to import the components individually.

@import 'bulma/sass/elements/box';
@import 'bulma/sass/elements/button';
@import 'bulma/sass/elements/container';
@import 'bulma/sass/elements/content';
//@import "bulma/sass/elements/form"; Not Needed
@import 'bulma/sass/elements/icon';
@import 'bulma/sass/elements/image';
@import 'bulma/sass/elements/notification';
@import 'bulma/sass/elements/progress';
@import 'bulma/sass/elements/table';
@import 'bulma/sass/elements/title';

//@import "bulma/sass/elements/tag";
//@import "bulma/sass/elements/other";
// These components break documentation.
