.custom-table {
  padding: 0.5rem 1rem;
}

.ambassador-card {
  font-size: 1em;
}

.ambassador-card-name {
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 0.9rem;
}

.custom-table > :last-child {
  border-bottom: none;
}

.custom-table > * {
  border-bottom: solid 1px;
}

.buttons-box {
  display: flex;
  margin-top: 1.5rem;
  justify-content: space-between;
}

.buttons-box > * {
  flex: 1;
}

.buttons-box-right-button {
  margin-left: 10px;
}

.listRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.listRow .left {
  -webkit-order: 1;
  -moz-order: 1;
  -ms-order: 1;
  order: 1;
}

.listRow .right {
  -webkit-order: 3;
  -moz-order: 3;
  -ms-order: 3;
  order: 3;
}

.listRow .middle {
  position: relative;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-order: 2;
  -moz-order: 2;
  -ms-order: 2;
  order: 2;
  align-items: center;
  margin: auto;
}

.middle .dots {
  height: 0.7em;
  align-items: center;
  margin: auto 4px;
  background: -webkit-radial-gradient(50% 50%, circle, gray 12%, transparent 15%);
  background: -moz-radial-gradient(50% 50%, circle, gray 12%, transparent 15%);
  background: radial-gradient(circle at 50% 50%, gray 12%, transparent 15%);
  background-size: 1em 1em;
  background-repeat: repeat-x;
}
