.auth-container {
  justify-content: center;
  align-items: center;
  background: #34495e;
}

.password-haeder {
  color: white;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.login-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 1000%;
}

.login-box {
  position: relative;
  display: flex;
  flex-direction: column;
}

.password-input-box {
  margin-bottom: 2rem;
}

.password-input {
  height: 3rem;
  min-width: 20rem;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 1rem;
  text-align: left;
  background-color: var(--dark-color);
  color: white;
}

.password-input-regular {
  border: 1px solid white;
  border-radius: 5px;
}

.password-input-regular input:focus {
  outline: none;
  border: 1px solid white;
  box-shadow: 0 0 5px white;
  border-radius: 5px;
}

.password-input-error {
  border: 1px solid red;
  border-radius: 5px;
}

.password-input-error input:focus {
  outline: none;
  box-shadow: 0 0 5px red;
  border: 1px solid red;
  border-radius: 5px;
}

.password-input-error-message {
  position: absolute;
  color: red;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  margin-top: 0.1rem;
}

.password-input-error-message::before {
  content: '⚠️';
  margin-right: 0.5rem;
}

.auth-button-box {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
}

.password-change-success {
  position: absolute;
  color: var(--primary-color);
  font-size: 0.8rem;
  margin-left: -0.5rem;
  margin-top: -1rem !important;
  text-align: center;
}

.password-change-success::before {
  content: '✓';
  margin-right: 0.5rem;
}

.password-input::placeholder {
  color: #bdc3c7;
}

.password-label {
  color: white;
  padding-left: -0.5rem;
}

.login-back-arrow {
  position: absolute;
  top: 2rem;
  left: 2rem;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

.forgot-password-txt {
  color: white;
  margin-top: 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  text-decoration: underline;
}
