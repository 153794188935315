.primary-button {
  transition: 100ms;
  background-color: var(--primary-color) !important;
  color: white !important;
  &:hover {
    background-color: var(--primary-color-hover) !important;
  }
}

.login-button {
  color: white !important;
}

.sign-up {
  background-color: var(--dark-color);
  position: relative;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    width: 400vh;
    background-color: #00000010;
    z-index: -1;
    height: 300px;
    top: -4rem;
    left: -100vw;
    transform: rotate(10deg);

    @media (max-width: 700px) {
      top: 13rem;
    }
  }
}

.join-login-buttons {
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    .button {
      margin-bottom: 1rem;
    }

    .become-ambassador-button {
      width: 10.5rem;
    }
  }
}
