.footer-container {
  position: relative;
  bottom: 0;
  background-color: var(--dark-color);
  padding-top: 8rem;
  padding-bottom: 7rem;

  color: white;

  a {
    color: white;

    &:hover {
      color: var(--primary-color);
    }
  }

  .container {
    display: block;
    max-width: 1000px;
    width: 90%;
    margin: auto;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 0.1rem;
    }
  }

  .columns {
    display: flex;
    width: auto;
    justify-content: space-between;
  }
  .is-one-third {
    width: 33%;
  }

  .app-images {
    display: flex;
  }

  .level {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }

  .button {
    background-color: var(--primary-color);
    padding: 1rem 2rem;
    color: white;
    font-weight: bold;
    border: 0;
    cursor: pointer;

    &:hover {
      background-color: var(--primary-color-hover);
    }
  }

  .block {
    margin-top: 1rem;
    margin-left: 0.2rem;
    span {
      margin-right: 1rem;
    }
  }
}

@media (max-width: 700px) {
  .columns {
    flex-wrap: wrap;
    .column {
      width: 50%;
      margin-bottom: 2rem;
    }
  }

  .main-column {
    width: 100% !important;
    margin-bottom: 2rem;
  }
}

.footer-title {
  margin-bottom: 0.5rem;
}

.logo-subtext {
  margin-top: 0.5rem;
}
