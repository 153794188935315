.survey-message-box {
  padding: 1rem;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  opacity: 0.8;
  text-align: center;
  p {
    color: #000700;
  }
}

.loading {
  text-align: center;
  animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
  0%,
  100% {
    color: #000700;
  }
  50% {
    color: #006b4b;
  }
}
