.pulse-navbar {
  position: absolute;
}

.pulse-navbar-sticky {
  position: fixed;
}

.pulse-navbar,
.pulse-navbar-sticky {
  width: 100%;
  height: 63px;
  z-index: 1000;
  display: flex;
  background: rgb(56, 73, 92);
}

.navbar-inside-box {
  position: relative;
  margin: auto 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.navbar-logo-box {
  display: flex;
  flex-direction: row;
  width: auto;
  color: white;
}

.navbar-logo {
  height: 49px;
}

.horizontal-align {
  margin: auto 0;
}
