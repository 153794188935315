$green: #1dbb9b;
$dark: #34495e;

$success: $green;

@import '~bulma/sass/base/_all.sass';
@import '~bulma/sass/components/_all.sass';
@import '~bulma/sass/elements/_all.sass';
@import '~bulma/sass/form/_all.sass';
@import '~bulma/sass/grid/_all.sass';
@import '~bulma/sass/helpers/_all.sass';
@import '~bulma/sass/layout/_all.sass';
@import '~bulma/sass/utilities/_all.sass';
