.progress-bar {
  width: 100%;
  height: 1rem;
  padding: 0;
  border-radius: 290486px;
  position: relative;
  display: flex;
}

.square {
  border-radius: 0;
}

.inside-text {
  margin: auto;
  line-height: 100%;
  font-size: 0.75rem;
}
